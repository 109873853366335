div[id$='FormField'][id^='date'] button {
    display: none;
}

.dateInput {
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    -webkit-appearance: none;
    background: transparent;
    color: inherit;
    width: 100%;
    padding: 11px;
    font-weight: 600;
    border: none;
}