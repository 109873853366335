@media screen and (max-width: 770px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    #searchResults table tr {
        border-bottom: 2px solid #000091;
        display: block;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    table td {
        display: block;
        font-size: 0.8em;
        text-align: right;
        max-width: 100%;
    }

    table tr:last-child {
        border-bottom: 0;
    }

    table tr {
        border-bottom: 4px solid rgba(0,0,0,0.33);
        display: block;
    }
}
