.routedAnchor:hover {
    text-decoration-line: underline;
}

.routedAnchor {
    text-decoration-line: none;
    text-decoration: none;
    font-weight: bold;
}

.routedAnchorBlue {
    color: #000091;
}

.routedAnchorBlack {
    color: black;
}